<template>
  <div v-if="modelManagerStatus === 'success'" class="model-manager-container">
    <portal to="ant-toolbar">
      <module-navigation-bar title="BIM Models" />
    </portal>
    <div class="d-flex flex-grow-1 overflow-hidden">
      <panel-resizable
        :collapsible="false"
        :default-width="400"
        :min-width="100"
        class="ant-glass-background radius-0 ant-border-right"
        side="left"
        @resize-end="$refs['forge-viewer'].viewerService.resizeView()"
      >
        <div class="full-height overflow-y-auto">
          <v-tabs v-model="modelManagerTab" grow>
            <v-tab :disabled="!forgeToken"> Bucket</v-tab>
            <v-tab> ACC</v-tab>
          </v-tabs>
          <v-tabs-items v-model="modelManagerTab">
            <v-tab-item>
              <div
                v-if="bucketItemsLoading"
                class="d-flex justify-center align-center fill-height"
              >
                <ant-loading />
              </div>
              <div v-else>
                <div class="d-flex justify-center my-5">
                  <v-chip
                    v-if="forgeBucketStatus === 'success'"
                    color="green"
                    text-color="white"
                  >
                    Connected bucket '{{ modelManagerClient.bucket_key }}'
                  </v-chip>

                  <v-dialog v-else v-model="newBucketDialog" width="500">
                    <template #activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on">
                        <v-icon>mdi-plus</v-icon>
                        Create bucket
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title
                        class="justify-center text-uppercase headline"
                      >
                        Create new bucket
                      </v-card-title>

                      <v-form ref="bucket-form">
                        <div class="px-15">
                          <ant-input label="Bucket key">
                            <template #input-field>
                              <v-text-field
                                v-model="bucketKey"
                                :rules="[rules.required, rules.bucketKeyRule]"
                                filled
                                placeholder="Type"
                              ></v-text-field>
                            </template>
                          </ant-input>

                          <ant-input label="Policy key">
                            <template #input-field>
                              <v-select
                                v-model="policyKey"
                                :items="[
                                  'transient',
                                  'temporary',
                                  'persistent',
                                ]"
                                :rules="[rules.required]"
                                filled
                                placeholder="Type"
                              ></v-select>
                            </template>
                          </ant-input>
                        </div>
                      </v-form>

                      <v-card-actions
                        class="ant-dialog-actions-bg ant-border-top"
                      >
                        <v-spacer />
                        <v-btn
                          color="error"
                          small
                          text
                          @click="newBucketDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          elevation="0"
                          small
                          @click="createBucket()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>

                <transition name="fade">
                  <div class="ant-glass-background my-3 overflow-y-auto">
                    <dynamic-data-table
                      :is-loading="forgeBucketStatus === 'loading'"
                      :table-headers="headers"
                      :table-records="forgeBucketFiles"
                      table-title="Models"
                    >
                      <template #table-actions>
                        <v-file-input
                          v-model="modelFile"
                          :disabled="forgeBucketStatus !== 'success'"
                          class="flex"
                          dense
                          hide-details
                          label="upload model"
                          outlined
                          prepend-icon="mdi-paperclip"
                        />
                        <v-btn
                          :disabled="!selectedBucket || !modelFile"
                          class="ms-2"
                          icon
                          @click="uploadFile"
                        >
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      <template #table-row-actions="{ item }">
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              dense
                              @click="openModelImportDialog(item)"
                              v-on="on"
                            >
                              mdi-import
                            </v-icon>
                          </template>
                          <span>Import model objects</span>
                        </v-tooltip>
                        <v-tooltip v-if="isFileInAntModel(item)" bottom>
                          <template #activator="{ on, attrs }">
                            <v-icon dense @click="selectModel(item)" v-on="on">
                              mdi-cube-scan
                            </v-icon>
                          </template>
                          <span>View model in 3D viewer</span>
                        </v-tooltip>
                        <v-tooltip v-if="!isFileInAntModel(item)" bottom>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              dense
                              @click="addModelToTable(item)"
                              v-on="on"
                            >
                              mdi-plus
                            </v-icon>
                          </template>
                          <span>Add model to project</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              dense
                              @click="
                                modelDeleteItem = Object.assign({}, item);
                                modelDeleteDialog = true;
                              "
                              v-on="on"
                            >
                              mdi-delete-outline
                            </v-icon>
                          </template>
                          <span>Delete model</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              dense
                              @click="exportGeometryToObjFile(item)"
                              v-on="on"
                            >
                              mdi-file-export-outline
                            </v-icon>
                          </template>
                          <span>Export geometry to OBJ</span>
                        </v-tooltip>
                      </template>
                    </dynamic-data-table>
                  </div>
                </transition>

                <!-- #mapper -->
                <div v-if="mappingDisplayed">
                  <Mapper ref="mapper" :model="model" @update="saveMappings" />
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="my-2 d-flex flex-column justify-center flex-1 pa-5">
                <div class="my-2 flex-1 overflow-y-auto d-flex flex-column">
                  <v-progress-linear
                    :style="{ opacity: accTreeLoading ? 1 : 0 }"
                    color="primary"
                    indeterminate
                    rounded
                  />
                  <v-subheader style="height: auto">Folders</v-subheader>
                  <div class="overflow-y-auto flex-1">
                    <v-treeview
                      :items="accTree"
                      :load-children="fetchFolderContents"
                      :open.sync="folderTreeOpenItems"
                      class="fs-12"
                      dense
                      item-children="children"
                      item-key="id"
                      open-on-click
                    >
                      <template #prepend="{ item, open }">
                        <v-icon v-if="item.type === 'folders'" small>
                          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                        </v-icon>
                        <v-icon v-else-if="item.type === 'items'" small>
                          mdi-cube
                        </v-icon>
                        <v-icon v-else-if="item.type === 'versions'" small
                          >mdi-source-branch
                        </v-icon>
                      </template>
                      <template #append="{ item }">
                        <v-tooltip
                          v-if="
                            item.type === 'versions' && !item.addedToProject
                          "
                          left
                        >
                          <template #activator="{ on }">
                            <v-btn
                              :ripple="false"
                              icon
                              small
                              @click="addToProjectModels(item)"
                              v-on="on"
                            >
                              <v-icon small> mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Add to project</span>
                        </v-tooltip>
                        <v-tooltip v-if="item.type === 'versions'" left>
                          <template #activator="{ on }">
                            <v-btn
                              :ripple="false"
                              icon
                              small
                              @click="previewModel(item)"
                              v-on="on"
                            >
                              <v-icon small> mdi-cube-scan</v-icon>
                            </v-btn>
                          </template>
                          <span>View model in 3D viewer</span>
                        </v-tooltip>
                      </template>
                    </v-treeview>
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </panel-resizable>
      <div class="forgeViewer-container">
        <ant-toolbar-container ant-toolbar-prefix="model-manager" />
        <forge-viewer
          ref="forge-viewer"
          :ant-toolbar-options="{
            viewType: {
              display: true,
              enabled: false,
            },
            models: {
              display: false,
              enabled: false,
            },
            antTable: {
              display: false,
              enabled: false,
            },
            ghosting: {
              display: true,
              enabled: false,
            },
            modelTree: {
              display: true,
              enabled: false,
            },
            clearIsolation: {
              display: true,
              enabled: false,
            },
            objectProperties: {
              display: true,
              enabled: true,
            },
            sidebar: {
              display: true,
              enabled: false,
            },
          }"
          :client="modelManagerForgeClient"
          :extension-options="[]"
          :extensions="['Autodesk.DocumentBrowser', 'Autodesk.VisualClusters']"
          :models="modelManagerForgeModels"
          ant-toolbar-prefix="model-manager"
          style="height: calc(100% - 46px)"
          @modelsRendered="viewerRendered"
        />
      </div>

      <v-dialog
        key="delete-model"
        v-model="modelDeleteDialog"
        max-width="90%"
        width="unset"
        @click:outside="closeDeleteDialog"
        @keydown.esc="closeDeleteDialog"
      >
        <v-card v-if="modelDeleteItem">
          <v-card-title
            >Are you sure you want to delete this model?
          </v-card-title>
          <v-card-text>
            <div
              style="display: flex; flex-direction: column; align-items: center"
            >
              <v-checkbox
                v-model="modelDeleteActions.bucketDelete"
                label="Delete from bucket"
              />
              <v-checkbox
                v-if="isFileInAntModel(modelDeleteItem)"
                v-model="modelDeleteActions.projectDelete"
                label="Delete from project"
              />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDeleteDialog">
              Cancel
            </v-btn>
            <v-btn
              :disabled="
                !modelDeleteActions.projectDelete &&
                !modelDeleteActions.bucketDelete
              "
              color="red"
              text
              @click="deleteModel"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AutodeskService from '@/services/forge/autodesk';
import {
  clearAllRecords,
  createRecord,
  deleteRecord,
  updateRecord,
} from '@/services/api/record.api';
import DynamicDataTable from '@/components/DynamicDataTable';
import Mapper from '@/components/Modules/Model-Manager/Mapper';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar';
import {
  convertToURN,
  mapModels,
  saveModels,
} from '@/components/Modules/Model-Manager/utils/ModelManager+utils';
import { MODEL_MANAGER } from '@/modules/modules';
import AntLoading from '@/components/AntLoading';
import {
  ACC_ROUTE,
  fetchFolderContents,
  fetchItemVersions,
  fetchTopFolders,
} from '@/services/forge/autodesk-construction-cloud';
import moment from 'moment';
import { createRevision } from '@/services/api/revisions.api';
import { Portal } from 'portal-vue';
import AntToolbarContainer from '@/components/AntToolbarContainer';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import base64url from 'base64url';
import AntInput from '@/components/AntInput.vue';
import appConfig from '@/appConfig';

export default {
  name: 'AutodeskIntegration',
  components: {
    AntInput,
    PanelResizable,
    Portal,
    AntToolbarContainer,
    AntLoading,
    ModuleNavigationBar,
    Mapper,
    DynamicDataTable,
    ForgeViewer: () => import('@/components/Modules/Forge/ForgeViewer'),
  },
  data() {
    return {
      modelManagerTab: 0,
      bucketHeaders: [{ text: 'Name', value: 'bucketKey' }],
      headers: [
        { text: 'File', value: 'objectKey' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
        bucketKeyRule: (value) =>
          /^[-_.a-z0-9]{3,128}$/.test(value) ||
          'Bucket key must be at least 3 characters and all lower case',
      },
      isImportDisplayed: false,
      bucket: {
        bucketKey: undefined,
        policyKey: undefined,
      },
      model: undefined,
      modelFile: undefined,
      mappingCard: '',
      uploadingModel: false,
      mappingDisplayed: false,
      bucketFiles: [],
      modelDeleteActions: {
        bucketDelete: false,
        projectDelete: false,
      },
      modelDeleteDialog: false,
      modelDeleteItem: undefined,
      sidebarWidth: 550,
      accTree: [],
      accTreeLoading: false,
      folderTreeOpenItems: [],
      bucketAuthentication: false,
      accAuthentication: false,
      bucketItemsLoading: false,
      newBucketDialog: false,
      bucketKey: null,
      policyKey: null,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'forgeToken',
      'modelManagerData',
      'modelManagerStatus',
      'forgeBucketFiles',
      'forgeBucketStatus',
      'forgeBucket',
      'modelManagerModels',
      'modelManagerForgeClient',
      'modelProperties',
      'accAccessToken',
      'modelManagerForgeModelsTable',
      'modelManagerForgeModels',
      'modelManagerClient',
      'modelManagerForgeClientTable',
    ]),
    selectedBucket() {
      return this.modelManagerData.CFFA_FORGE_CLIENT.records[0].bucket_key;
    },
    isAccConnected() {
      return (
        this.accAccessToken !== undefined &&
        this.modelManagerStatus === 'success'
      );
    },
  },
  watch: {
    modelManagerTab(value) {
      this.displayModelManagerTab();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    viewerRendered(value) {
      this.$refs['forge-viewer'].setExternalMapping(value.myData.urn);
    },
    loadData() {
      this.$store
        .dispatch('loadModelManagerModuleData', {
          projectId: this.project.id,
          moduleId: this.project.modules.find((m) => m.route === MODEL_MANAGER)
            .id,
          sessionId: this.$route.params.sessionId ?? null,
        })
        .then(() => {
          if (this.$route.query.tab) {
            this.modelManagerTab = parseInt(this.$route.query.tab);
          } else {
            this.displayModelManagerTab();
          }
        });
    },
    previewModel(item) {
      this.modelManagerForgeModels.forEach((model) => (model.enabled = false));

      let record = {
        isPreview: true,
        urn: base64url(item.data.id),
        name: item.data.attributes.name,
        version: item.data.attributes.versionNumber,
        enabled: true,
        from_acc: true,
      };

      this.modelManagerForgeModels.push(record);
      this.$refs['forge-viewer'].loadViewer();
    },
    async displayModelManagerTab() {
      switch (this.modelManagerTab) {
        case 0:
          try {
            await this.forgeAuthenticate();
          } catch (e) {
            this.$store.commit('showNotification', {
              content: 'Cannot authenticate bucket',
              color: 'warning',
            });
            this.modelManagerTab = 1;
          }
          if (this.forgeBucket) {
            try {
              this.bucketItemsLoading = true;
              await this.$store.dispatch('fetchBucketFiles', this.forgeBucket);
              this.bucketItemsLoading = false;
            } catch (e) {
              this.$store.commit('showNotification', {
                content: 'Cannot authenticate bucket',
                color: 'warning',
              });
              this.bucketItemsLoading = false;
            }
          }
          break;
        case 1:
          if (!this.accAccessToken) {
            this.$cookies.set(ACC_ROUTE, this.$route.fullPath);
            window.open(
              `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=${
                appConfig.VUE_APP_ACC_CLIENT_ID
              }&redirect_uri=${encodeURI(
                appConfig.VUE_APP_ACC_CLIENT_CALLBACK_URL
              )}&scope=data:read`,
              '_self'
            );
          } else {
            await this.fetchAccProjectTopFolders();
          }
      }
    },
    async createBucket() {
      if (this.$refs['bucket-form'].validate()) {
        try {
          const bucket = await this.$store.dispatch('createBucket', {
            bucketKey: this.bucketKey,
            policyKey: this.policyKey,
          });

          await updateRecord(this.modelManagerForgeClient.id, {
            project: {
              id: this.project.id,
            },
            table: {
              id: this.modelManagerForgeClientTable,
            },
            record: {
              bucket_key: bucket.bucketKey,
            },
          });

          this.newBucketDialog = false;
          this.loadData();
        } catch (e) {}
      }
    },
    async fetchAccProjectTopFolders() {
      this.accTreeLoading = true;
      this.accTree = [];
      const topFolders = await fetchTopFolders(
        this.accAccessToken,
        this.modelManagerForgeClient.acc_hub,
        this.modelManagerForgeClient.acc_project
      );
      topFolders.forEach((folder) => {
        this.accTree.push({
          id: folder.id,
          type: folder.type,
          name: folder.attributes.name,
          children: [],
        });
      });
      this.accTreeLoading = false;
    },
    fetchFolderContents(item) {
      if (item.type === 'folders') {
        this.accTreeLoading = true;
        fetchFolderContents(
          this.accAccessToken,
          this.modelManagerForgeClient.acc_project,
          item.id
        ).then((contents) => {
          let treeContent = [];
          contents.forEach((contentItem) => {
            let data = {
              id: contentItem.id,
              name:
                contentItem.attributes.name !== undefined
                  ? contentItem.attributes.name
                  : contentItem.attributes.displayName,
              type: contentItem.type,
            };
            if (data.type === 'folders') {
              data.children = [];
            }
            if (data.type === 'items') {
              data.children = [];
            }
            treeContent.push(data);
          });
          item.children.push(...treeContent);
          this.folderTreeOpenItems.push(item.id);
          this.accTreeLoading = false;
          return contents;
        });
      } else if (item.type === 'items') {
        this.accTreeLoading = true;
        fetchItemVersions(
          this.accAccessToken,
          this.modelManagerForgeClient.acc_project,
          item.id
        ).then((versions) => {
          let treeContent = [];
          versions.forEach((versionItem) => {
            let data = {
              id: versionItem.id,
              name: `v${versionItem.attributes.versionNumber}`,
              type: versionItem.type,
              data: versionItem,
              addedToProject:
                this.modelManagerForgeModels
                  .filter((record) => !record.isPreview)
                  .findIndex(
                    (record) =>
                      record.name === versionItem.attributes.name &&
                      record.version === versionItem.attributes.versionNumber &&
                      record.from_acc
                  ) !== -1,
            };
            treeContent.push(data);
          });
          item.children.push(...treeContent);
          this.folderTreeOpenItems.push(item.id);
          this.accTreeLoading = false;
          return versions;
        });
      }
    },
    async addToProjectModels(item) {
      console.log(item);
      const data = {
        project: {
          id: this.project.id,
        },
        table: {
          id: this.modelManagerForgeModelsTable,
        },
        record: {
          urn: base64url(item.data.id),
          name: item.data.attributes.name,
          version: item.data.attributes.versionNumber,
          from_acc: true,
        },
      };
      await this.$store.dispatch('addModelToProject', data);
      item.addedToProject = true;
    },
    closeDeleteDialog() {
      this.modelDeleteDialog = false;
      this.modelDeleteItem = undefined;
      this.modelDeleteActions = {
        bucketDelete: false,
        projectDelete: false,
      };
    },
    exportGeometryToObjFile(item) {
      const region =
        this.modelManagerData.CFFA_FORGE_CLIENT.records[0].server_region;
      AutodeskService.getUrnMetadata(
        convertToURN(item.objectId),
        this.forgeToken,
        region
      ).then((data) => {
        const guid = data.find((e) => e.role === '3d')?.guid;
        AutodeskService.getGuidProperties(
          convertToURN(item.objectId),
          guid,
          this.forgeToken,
          region
        ).then((response) => {
          let objectIds = response.map((x) => x.objectid);

          AutodeskService.extractGeometry(
            convertToURN(item.objectId),
            this.forgeToken,
            region,
            objectIds,
            guid
          ).then((jobResponse) => {
            this.checkExtractJobStatus(
              convertToURN(item.objectId),
              this.forgeToken,
              region
            );
          });
        });
      });
    },

    checkExtractJobStatus(urn, token, region) {
      AutodeskService.getUrnManifest(urn, this.forgeToken, region).then(
        (manifestResponse) => {
          let derivative = manifestResponse.derivatives.find(
            (x) => x.outputType === 'obj'
          );
          if (derivative !== undefined) {
            if (derivative.status === 'success') {
              let objChild = derivative.children.find((child) =>
                child.urn.endsWith('.obj')
              );
              if (objChild !== undefined) {
                AutodeskService.downloadObjExtraction(
                  urn,
                  token,
                  region,
                  objChild.urn
                ).then((download) => {
                  let a = window.document.createElement('a');
                  a.href = window.URL.createObjectURL(
                    new Blob([download], { type: 'text/obj' })
                  );
                  a.download = 'stapler-geometry.obj';

                  // Append anchor to body.
                  document.body.appendChild(a);
                  a.click();

                  // Remove anchor from body
                  document.body.removeChild(a);
                });
              }
            } else {
              setTimeout(() => {
                this.checkExtractJobStatus(urn, token, region);
              }, 5000);
            }
          }
        }
      );
    },
    isFileInAntModel(file) {
      return (
        this.modelManagerData.CFFA_FORGE_MODELS.records.find(
          (record) => record.urn === convertToURN(file.objectId)
        ) !== undefined
      );
    },
    saveMappings(data) {
      const tableId = data.table;
      createRevision({
        project: {
          id: this.project.id,
        },
        table: {
          id: tableId,
        },
        reason: `import_objects_${moment().format('YYYY-MM-DD HH:mm:ss')}`,
      }).then((revision) => {
        clearAllRecords(this.project.id, tableId).then(async (response) => {
          const mappedModels = mapModels(
            this.modelManagerModels,
            this.modelProperties,
            data
          )
            .map((m) => ({ id: undefined, ...m }))
            .filter((m) => !Object.values(m).every((v) => v === undefined));

          const chunkSize = 5000;
          let count = 0;
          let total = Math.ceil(mappedModels.length / chunkSize);
          for (let i = 0; i < mappedModels.length; i += chunkSize) {
            const chunk = mappedModels.slice(i, i + chunkSize);
            await saveModels(chunk, this.project.id, tableId).then(() => {
              count++;
              this.$refs.mapper.$refs.importDialog.percentageValue = Math.floor(
                (count / total) * 100
              );
            });
          }
          this.mappingDisplayed = false;
        });
      });
    },
    async forgeAuthenticate() {
      await this.$store.dispatch('saveCredentials', this.modelManagerClient);
      this.token = await AutodeskService.fetchToken(
        this.modelManagerClient.client_id,
        this.modelManagerClient.secret,
        this.modelManagerClient.server_region
      );
      await this.$store.dispatch('setForgeToken', {
        token: this.token,
      });
    },
    openModelImportDialog(model) {
      this.model = model;
      this.mappingDisplayed = true;
    },
    addModelToTable(file) {
      createRecord({
        project: {
          id: this.project.id,
        },
        table: {
          id: this.modelManagerData.CFFA_FORGE_MODELS.id,
        },
        record: {
          urn: convertToURN(file.objectId),
          name: file.objectKey,
        },
      }).then((record) => {
        this.$store.commit('ant_record_model_create', record);
        this.$store.commit('showNotification', {
          content: `Added model to project`,
          color: 'success',
        });
      });
    },
    deleteModel() {
      if (this.modelDeleteActions.bucketDelete) {
        this.$store
          .dispatch('removeModelFromBucket', {
            bucket: this.forgeBucket,
            model: this.modelDeleteItem.objectKey,
          })
          .then(() => {});
      }

      if (this.modelDeleteActions.projectDelete) {
        this.removeModelFromTable(this.modelDeleteItem);
      }

      this.closeDeleteDialog();
    },
    removeModelFromTable(file) {
      let antRecord = this.modelManagerData.CFFA_FORGE_MODELS.records.find(
        (x) => x.urn === convertToURN(file.objectId)
      );
      deleteRecord(antRecord.id, {
        project: {
          id: this.project.id,
        },
        table: {
          id: this.modelManagerData.CFFA_FORGE_MODELS.id,
        },
      }).then(() => {
        this.$store.commit('ant_record_model_delete', antRecord.id);
        this.$store.commit('showNotification', {
          content: `Removed model from project`,
          color: 'success',
        });
      });
    },
    uploadFile() {
      this.$store
        .dispatch('uploadForgeFile', {
          selectedBucket: this.selectedBucket,
          file: this.modelFile,
        })
        .then((response) => {
          this.$store.dispatch('convertModel', convertToURN(response.objectId));
          this.modelFile = undefined;
        });
    },
    selectModel(object) {
      this.$refs['forge-viewer'].models.forEach(
        (model) => (model.enabled = false)
      );

      this.$refs['forge-viewer'].models.find(
        (x) => x.urn === convertToURN(object.objectId)
      ).enabled = true;

      this.$refs['forge-viewer'].loadViewer();
    },
    startResizeSidebar(e) {
      document.addEventListener('mousemove', this.dragSidebar);
      document.addEventListener('mouseup', this.stopResizeSidebar);
    },
    dragSidebar(e) {
      this.sidebarWidth = e.clientX + 12;
    },

    stopResizeSidebar(e) {
      document.removeEventListener('mousemove', this.dragSidebar);
      document.removeEventListener('mouseup', this.stopResizeSidebar);
    },
  },
};
</script>

<style lang="scss" scoped>
.model-manager-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.uploadForgeModel {
  margin-top: 30px;
}

.noURL {
  text-decoration: none;
  color: #2c3e50 !important;
}

.forgeViewer-container {
  flex: 1;
}
</style>
